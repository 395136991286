import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, makeStyles } from '@popmenu/common-ui';

import { classNames } from '../../utils/withStyles';

const useStyles = makeStyles(theme => ({
  skipToMainContentButton: {
    '&:focus-within': {
      clip: 'auto',
      height: 'auto',
      left: theme.spacing(1),
      margin: 'auto',
      overflow: 'visible',
      top: theme.spacing(1),
      width: 'auto',
      zIndex: 9999999,
    },
  },
}));

export const SkipToContentButton = ({ mainContentRef }: { mainContentRef: React.RefObject<HTMLDivElement> }) => {
  const classes = useStyles();
  return (
    <div
      className={classNames(
        'sr-only',
        classes.skipToMainContentButton,
      )}
    >
      <Button
        component="a"
        href="#page-content"
        onClick={(e) => {
          e.stopPropagation();
          if (mainContentRef.current) {
            mainContentRef.current.focus();
          }
          return false;
        }}
        rel="nofollow"
        variant="contained"
      >
        <FormattedMessage
          id="consumer_container.skip_to_main_content_link"
          defaultMessage="Skip to main content"
        />
      </Button>
    </div>
  );
};
