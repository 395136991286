import React, { useRef } from 'react';
import { Typography, type TypographyProps } from '@popmenu/common-ui';
import { classNames } from '~/utils/withStyles';
import { ParentIdContext, useAccessibleHeadingLevel, useLevelProviderId } from './useAccessibleHeadingLevel';

const invisibleStyle = { display: 'none' };

// For tests
export let headingRendersCount = 0;
export let levelProviderRendersCount = 0;
/** @internal used by tests */
export function resetHeadingRendersCount() {
  headingRendersCount = 0;
}
/** @internal used by tests */
export function resetLevelProviderRendersCount() {
  levelProviderRendersCount = 0;
}

type HeadingVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface AHProps extends Omit<TypographyProps, 'ref'> {
  /** Class names unrelated to typography. */
  className?: string;
  /**
   * @deprecated `Typography.component` prop is set automatically inside AH. You cannot override it.
   * `AH.component` prop exists for legacy CSS migrations. It doesn't make sense to use it in new components.
   * Use variant to control styles instead.
   */
  component?: HeadingVariant;
  /** Whether to use `<Typography variant="h*" ... />` or `<h* />`. */
  typography?: boolean;
  /** Determines class name `.pm-h*`. */
  variant: HeadingVariant;
}

// AH means Accessible Heading or Automatic Heading.
//
// See https://web.dev/heading-order/
// Instead of hardcoding headings with h1/h2/h3/h4/h5/h6 use this component.
// It computes heading level by counting AHLevelProvider layers.
//
// Example:
//  <AH> This text is h1 </AH>
//  <AHLevelProvider>
//    <AH> This text is h2 </AH>
//    <AHLevelProvider>
//      <AH> This text is h3 </AH>
//    </AHLevelProvider>
//    <AH> This text is h2 </AH>
//  </AHLevelProvider>
//
// For more examples see spec/cypress/component/consumer/shared/AccessibleHeading.cy.tsx
export const AH = (
  {
    children,
    className = '',
    component = undefined,
    typography = false,
    variant,
    ...otherProps
  } : AHProps,
) => {
  headingRendersCount += 1;

  const ref = useRef<HTMLHeadingElement>(null);
  const level = useAccessibleHeadingLevel(ref);

  let legacyHeadingLevel: number | null = null;
  if (component && component.length === 2) {
    legacyHeadingLevel = component[1] ? parseInt(component[1], 10) : null;
  } else if (variant.length === 2) {
    legacyHeadingLevel = variant[1] ? parseInt(variant[1], 10) : null;
  }

  // The goal is to change heading level without changing styles. pm-h* and pm-AH class names are used for custom CSS conversion.
  const updatedClassName = classNames(className, 'pm-AH', `pm-h${legacyHeadingLevel}`);
  if (typography) {
    return (
      <Typography
        TypographyRef={ref}
        variant={variant}
        className={updatedClassName}
        {...otherProps}
        component={`h${level}`}
      >
        {children}
      </Typography>
    );
  }

  const Tag = ({
    1: 'h1',
    2: 'h2',
    3: 'h3',
    4: 'h4',
    5: 'h5',
    6: 'h6',
  } as const)[level] ?? 'h6';
  return <Tag {...otherProps} ref={ref} className={updatedClassName}>{children}</Tag>;
};

interface AHLevelProviderProps {
  children: React.ReactNode;
}

export const AHLevelProvider = ({ children }: AHLevelProviderProps) => {
  levelProviderRendersCount += 1;

  const ref = useRef<HTMLDivElement>(null);
  const id = useLevelProviderId(ref);
  return (
    <ParentIdContext.Provider value={id}>
      <wbr style={invisibleStyle} ref={ref} />
      {children}
    </ParentIdContext.Provider>
  );
};
