import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';
import { Link, withRouter } from 'react-router-dom';
import { Edit } from '@popmenu/web-icons';

import { formatPhone, toLocationMapUrl } from '../../../utils/utils';
import { themeShape, withTheme } from '../../../utils/withTheme';
import { closeNavModal, openNavModal } from '../../../shared/ModalActions';
import { withWindowContext } from '../../../shared/WindowProvider';
import { withWindowSizeContext } from '../../../shared/WindowSizeProvider';
import { withIntl } from '../../../utils/withIntl';
import { classNames, withStyles } from '../../../utils/withStyles';
import navStyles from './styles';

import BasicModal from '../../../admin/shared/BasicModal';
import CartButton from '../../menus/cart/CartButton';
import LocationAddressDisplay from '../LocationAddressDisplay';
import NavItemsV2 from '../NavItemsV2';
import NavProfileButton from './NavProfileButton';
import { readableFontColor } from '../../../utils/colors';

class Nav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showMobileNav: props.isMobile,
    };
    this.brandRef = React.createRef();
    this.navbarRef = React.createRef();
    this.sectionAction = this.sectionAction.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isMobile !== this.props.isMobile) {
      const showMobileNav = this.props.isMobile;
      this.setState({ showMobileNav });
    }
  }

  sectionAction(page, navItem = null) {
    window.parent.postMessage({ immutableSection: { isCustomSection: true, navItem, sectionType: 'Nav' }, page }, '*');
  }

  previewControlClass() {
    if (this.props.activeSection?.sectionType === 'Nav') {
      return 'preview-section-container-activable';
    }
    return 'preview-section-container-hoverable';
  }

  render() {
    const currentCustomPage = this.props.restaurant.customPages.find((page) => {
      const slug = this.props.location.pathname.split('/', 2)[1];
      return (page.isHomePage && this.props.location.pathname === '/') || page.slug === slug;
    });
    if (currentCustomPage && !currentCustomPage.isNavEnabled) {
      return null;
    }
    const showNavbarLogo = this.props.scrolled || !currentCustomPage || currentCustomPage.navbarLogoToggle;

    const { headerBackgroundEffect, headerNavFontColor, navbarBgColor } = this.props.theme;
    const defaultNavBgEffect = (headerBackgroundEffect === 'fxhbg_white' ? '#FFFFFF' : '#000000');
    const modalBackgroundColor = '#060606';
    const visibleColorHeader = (navbarBgColor ? readableFontColor(navbarBgColor, undefined) : readableFontColor(defaultNavBgEffect, headerNavFontColor));
    const mobileIconStyle = { backgroundColor: visibleColorHeader };
    let disableHomeAjax = false;
    if (this.props.restaurant.homePage) {
      disableHomeAjax = this.props.restaurant.homePage.disableAjaxLoad;
    }
    let location;
    if (this.props.restaurant.locationsCount === 1) {
      location = this.props.restaurant.firstLocation;
    }
    const clickHandler = {
      onClick: () => {
        if (!this.props.isPreview || !currentCustomPage) {
          return;
        }
        this.sectionAction(currentCustomPage.id);
      },
    };

    const isSplit = this.props.theme.headerLogoAlignment === 'c_nav_b';

    return (
      <React.Fragment>
        <nav
          {...clickHandler}
          className={classNames(
            'navbar',
            `brand-size-${this.props.theme.headerLogoSize}`,
            `brand-fx-${this.props.theme.headerLogoEffect}`,
            `navbar-${this.props.theme.headerLogoAlignment}`,
            `navbar-bg-${this.props.theme.headerBackgroundEffect}`,
            this.props.scrolled ? 'toggle-scrolled' : null,
            this.state.showMobileNav ? 'navbar-mobile' : null,
            this.props.isPreview ? this.previewControlClass() : null,
          )}
          id="navbar"
          ref={this.navbarRef}
          role="navigation"
          style={{
            backgroundColor: this.props.theme.navbarBgColor,
          }}
        >
          {this.props.isPreview && (
            <div style={{ right: '42px' }} className="preview-section-container-controls">
              <Edit onClick={() => this.sectionAction(currentCustomPage.id)} />
            </div>
          )}
          <div className={this.props.theme.headerLogoAlignment === 'c_nav_b' ? 'container-fluid' : 'container'} style={{ width: '100%' }}>
            <div className="row">
              <div className="col-xs-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="navbar-header" style={{ width: this.props.isMobile ? '100%' : 'auto' }}>
                  <button
                    aria-expanded={String(this.props.showNavModal)}
                    aria-label="Navigation Menu"
                    className="navbar-toggle"
                    onClick={() => this.props.openNavModal()}
                    type="button"
                  >
                    <span className="icon-bar" style={mobileIconStyle} />
                    <span className="icon-bar" style={mobileIconStyle} />
                    <span className="icon-bar" style={mobileIconStyle} />
                  </button>
                  <NavProfileButton
                    restaurant={this.props.restaurant}
                    classes={this.props.classes}
                    isMobile={this.props.isMobile}
                  />
                  {/*
                    CAUTION! CartButton must be the last nav element with float:right CSS rule.
                    Otherwise it will produce layout shifts, because CartButton shows up after session query is loaded.
                  */}
                  <CartButton
                    onCheckoutButtonClick={this.props.onCheckoutButtonClick}
                    onGoToMenuButtonClick={this.props.onGoToMenuButtonClick}
                    theme={this.props.theme}
                    featureSetting={this.props.restaurant.featureSetting}
                  />
                  {disableHomeAjax ? (
                    <a
                      href="/"
                      ref={(ref) => { this.brandRef.current = ref; }}
                      className={`navbar-brand${this.props.restaurant.logoUrl ? ' navbar-brand-logo' : ''}`}
                      style={{
                        backgroundImage: this.props.restaurant.logoUrl ? `url("${this.props.restaurant.logoUrl}")` : 'none',
                        color: this.props.restaurant.logoUrl ? 'inherit' : visibleColorHeader,
                        visibility: showNavbarLogo ? 'visible' : 'hidden',
                      }}
                    >
                      <span className={classNames('navbar-heading', this.props.classes.navbarHeading)}>
                        {this.props.restaurant.name}
                      </span>
                    </a>
                  ) :
                    (
                      <Link
                        aria-label={this.props.restaurant.logoUrl ? `${this.props.restaurant.name}-logo` : ''}
                        to="/"
                        className={`navbar-brand${this.props.restaurant.logoUrl ? ' navbar-brand-logo' : ''}`}
                        innerRef={(ref) => { this.brandRef.current = ref; }}
                        style={{
                          backgroundImage: this.props.restaurant.logoUrl ? `url("${this.props.restaurant.logoUrl}")` : 'none',
                          color: this.props.restaurant.logoUrl ? 'inherit' : visibleColorHeader,
                          visibility: showNavbarLogo ? 'visible' : 'hidden',
                        }}
                      >
                        <span className={classNames('navbar-heading', this.props.classes.navbarHeading)}>
                          {this.props.restaurant.logoUrl ? `${this.props.restaurant.name}-logo` : this.props.restaurant.name}
                        </span>
                      </Link>
                    )}
                </div>
                <div className={classNames('navbar-collapse', isSplit ? this.props.classes.navbarCollapseSplit : null)} style={{ display: this.props.isMobile ? 'none' : 'flex' }}>
                  <NavItemsV2
                    className="nav navbar-nav"
                    restaurant={this.props.restaurant}
                    sectionAction={navItem => this.sectionAction(currentCustomPage.id, navItem)}
                    isPreview={this.props.isPreview}
                    isSplit={isSplit}
                  />
                </div>
              </div>
            </div>
          </div>

          <BasicModal
            backgroundColor={modalBackgroundColor}
            closeModal={() => this.props.closeNavModal()}
            paper={false}
            PaperProps={{
              'aria-label': 'Navigation Menu',
            }}
            show={this.props.showNavModal}
          >
            <div className="navbar-modal">
              {this.props.restaurant.showNavLocation && location && (
                <React.Fragment>
                  <div className="location">
                    <a aria-describedby="aria-new-window-2" href={toLocationMapUrl(location)} rel="noopener" target="_blank">
                      <LocationAddressDisplay location={location} />
                    </a>
                    {location.displayPhone && (
                      <a
                        aria-label={`${location.displayPhone} ${this.props.t('generic.telephone')}`}
                        href={`tel:${location.displayPhone}`}
                      >
                        {formatPhone(location.displayPhone)}
                      </a>
                    )}
                  </div>
                </React.Fragment>
              )}
              <NavItemsV2
                noBackground
                onClick={() => this.props.closeNavModal()}
                restaurant={this.props.restaurant}
              />
            </div>
          </BasicModal>
        </nav>
      </React.Fragment>
    );
  }
}

Nav.propTypes = {
  addResizeListener: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  closeNavModal: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  onCheckoutButtonClick: PropTypes.func.isRequired,
  onGoToMenuButtonClick: PropTypes.func.isRequired,
  openNavModal: PropTypes.func.isRequired,
  removeResizeListener: PropTypes.func.isRequired,
  restaurant: PropTypes.shape({
    customPages: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      isHomePage: PropTypes.bool,
      navbarLogoToggle: PropTypes.bool,
      slug: PropTypes.string,
      tabName: PropTypes.string,
    })),
    firstLocation: PropTypes.shape({
      id: PropTypes.number,
      menus: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string,
      })),
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
    homePage: PropTypes.PropTypes.shape({
      disableAjaxLoad: PropTypes.bool,
    }),
    logoUrl: PropTypes.string,
    name: PropTypes.string,
    navItems: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
    rootAnnouncement: PropTypes.shape({
      content: PropTypes.string,
      country: PropTypes.string,
      id: PropTypes.number,
      linkUrl: PropTypes.string,
    }),
    showNavLocation: PropTypes.bool,
  }).isRequired,
  scrolled: PropTypes.bool.isRequired,
  showNavModal: PropTypes.bool.isRequired,
  theme: themeShape.isRequired,
};

export default compose(
  withTheme,
  withStyles(navStyles),
  withRouter,
  connect(state => ({
    activeSection: state.consumer.activeSection,
    isPreview: state.consumer.isPreview,
    showNavModal: state.modals.showNavModal,
  }), {
    closeNavModal,
    openNavModal,
  }),
  withWindowContext,
  withWindowSizeContext,
  withIntl,
  mapProps(({ window, windowSize, ...props }) => ({
    ...props,
    addResizeListener: windowSize.addResizeListener,
    isMobile: windowSize.isMobile,
    removeResizeListener: windowSize.removeResizeListener,
    scrolled: window.scrolledHeader,
  })),
)(Nav);
