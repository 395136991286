import {
  addSeconds,
  differenceInMinutes,
  eachDayOfInterval,
  format,
  formatDistance as dateFnsFormatDistance,
  getTime as dateFnsGetTime,
  isAfter as dateFnsIsAfter,
  isEqual,
  isSameDay,
  parseISO as dateFnsParseISO,
  startOfToday,
  subDays,
  addMinutes,
  startOfDay,
  endOfDay,
} from 'date-fns';
import { format as dateFnsFormat, formatInTimeZone } from 'date-fns-tz';
import capitalize from 'lodash/capitalize';
import { getLocale } from './locale';

import { padLeft } from './strings';
import type { CalendarEvent } from '../libs/gql/types';

// Reference for options:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
const formatOptionsMap = new Map<string, Intl.DateTimeFormatOptions>([
  ['M/d', { day: 'numeric', month: 'numeric' }],
  ['MMMM d', { day: 'numeric', month: 'long' }],
  ['MM/dd', { day: '2-digit', month: '2-digit' }],
  ['h:mm a', { hour: '2-digit', minute: '2-digit' }],
  ['h:mm a z', { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' }],
  ['M/d h:mm a z', { day: 'numeric', hour: '2-digit', minute: '2-digit', month: 'numeric', timeZoneName: 'short' }],
  ['MMM d, yyyy h:mm a z', { day: 'numeric', hour: '2-digit', minute: '2-digit', month: 'short', timeZoneName: 'short', year: 'numeric' }],
  ['MMM d', { day: 'numeric', month: 'short' }],
]);

export const formatDateLocalized = (date: Date | number, dateFormat = 'M/d'): string => {
  if (formatOptionsMap.has(dateFormat)) {
    return Intl.DateTimeFormat(getLocale(), formatOptionsMap.get(dateFormat)).format(date);
  } else {
    return dateFnsFormat(date, dateFormat);
  }
};

// Named to be clear how it's used, but the functions get called with unknown in table column definitions.
// TODO: After sc-57827 check if we can use `Optional<string | number | Date>`.
export type DirtyDate = unknown;

export function parseDate(dirtyDate: Date | number): Date;
export function parseDate(dirtyDate: DirtyDate): Date | null;
export function parseDate(dirtyDate: DirtyDate) {
  if (!dirtyDate || dirtyDate === 'auto') {
    return null;
  }
  if (typeof dirtyDate === 'string') {
    return dateFnsParseISO(dirtyDate);
  } else if (typeof dirtyDate === 'number') {
    return new Date(dirtyDate);
  } else if (dirtyDate instanceof Date) {
    return dirtyDate;
  } else {
    return null;
  }
}

export function formatDate(dirtyDate: Date | number, dateFormat?: string, options?: { utc?: boolean }): string;
export function formatDate(dirtyDate: DirtyDate, dateFormat?: string, options?: { utc?: boolean }): string | null;
export function formatDate(dirtyDate: DirtyDate, dateFormat = 'M/d/yyyy', { utc = false } = {}): string | null {
  let date = parseDate(dirtyDate);

  if (!date) {
    return null;
  }

  if (utc) {
    date = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  }
  if (dateFormat === 'unix') {
    return dateFnsGetTime(date).toString();
  }
  if (dateFormat === 'ISO') {
    return dateFnsFormat(date, `yyyy-MM-dd'T'HH:mm:ss${utc ? '-00:00' : 'xxx'}`);
  }
  if (dateFormat === 'ago') {
    const now = new Date();
    const formattedDistance = dateFnsFormatDistance(date, now);
    if (formattedDistance === 'less than a minute') {
      return 'just now';
    }
    if (dateFnsIsAfter(now, date)) {
      return `${formattedDistance} ago`;
    }
    return `${formattedDistance} from now`;
  }
  if (getLocale() === 'en-US' || getLocale() === 'en') {
    return dateFnsFormat(date, dateFormat);
  } else {
    return formatDateLocalized(date, dateFormat);
  }
}

export const formatDateAgo = (date: DirtyDate) => formatDate(date, 'ago');

export const formatRelativeTime = (dirtyDate: DirtyDate) => {
  const date = parseDate(dirtyDate);
  if (date && isSameDay(date, Date.now())) {
    const minutes = Math.abs(differenceInMinutes(date, Date.now()));
    if (minutes < 45) {
      return formatDateAgo(date);
    }
    return `Today ${formatDate(date, 'h:mm a z')}`;
  }
  return formatDate(date, 'M/d h:mm a z');
};

export const format24HourRelativeTime = (dirtyDate: DirtyDate) => {
  const date = parseDate(dirtyDate);
  if (date) {
    const minutes = Math.abs(differenceInMinutes(date, Date.now()));
    // Count up by minutes/hours for 24 hours (1440 minutes)
    if (minutes < 1439) {
      return formatDateAgo(date);
    }
  }
  return formatDate(date, 'M/d h:mm a z');
};

export const formatTime = (secondsOfDay: Optional<number>) => {
  if (typeof secondsOfDay !== 'number') {
    return null;
  }
  let time = secondsOfDay;
  let am = 'am';
  if (time >= 46800) { // 1:00pm+
    time -= 43200;
    am = 'pm';
  } else if (time >= 43200) { // 12:00pm-12:59pm
    am = 'pm';
  } else if (time < 3600) { // 12:00am-12:59am
    time += 43200;
  }
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  return `${hours}:${padLeft(String(minutes), '0', 2)} ${am}`;
};

export const toDaysOfWeek = (calendarEvent: CalendarEvent, useAbbreviation: boolean = false) => {
  /* eslint-disable sort-keys */
  const daysOfWeek: Partial<Record<keyof CalendarEvent, string>> = {
    isMonday: 'Monday',
    isTuesday: 'Tuesday',
    isWednesday: 'Wednesday',
    isThursday: 'Thursday',
    isFriday: 'Friday',
    isSaturday: 'Saturday',
    isSunday: 'Sunday',
  };
  const daysOfWeekAbbr: Partial<Record<keyof CalendarEvent, string>> = {
    isMonday: 'Mon',
    isTuesday: 'Tue',
    isWednesday: 'Wed',
    isThursday: 'Thu',
    isFriday: 'Fri',
    isSaturday: 'Sat',
    isSunday: 'Sun',
  };
  /* eslint-enable sort-keys */

  const useWeekEntries = useAbbreviation ? daysOfWeekAbbr : daysOfWeek;
  const days: string[] = [];
  Object.entries(useWeekEntries).forEach(([attr, dayName]) => {
    // the cast is safe because we know there are no extra keys in daysOfWeek
    if (calendarEvent[attr as keyof CalendarEvent]) {
      days.push(dayName);
    }
  });
  return days.join('/');
};

export const fullDaysBetween = (first: DirtyDate, second: DirtyDate) => {
  const day1 = parseDate(first);
  const day2 = parseDate(second);
  if (day1 === null || day2 === null) {
    return '0 days';
  }
  const days = Math.round(Math.abs((day2.getTime() - day1.getTime()) / (24 * 60 * 60 * 1000)));
  return `${days} ${days === 1 ? 'day' : 'days'}`;
};

/* eslint-disable sort-keys */
const dayMap = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
} as const;

const allDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'] as const;
/* eslint-enable sort-keys */

export type LowercaseWeekDay = typeof allDays[number];
export type CapitalizedWeekDay = Capitalize<LowercaseWeekDay>;
export type WeekDay = LowercaseWeekDay | CapitalizedWeekDay | Uppercase<LowercaseWeekDay>;

const formatDays = (days: LowercaseWeekDay[], capify: boolean = false) => (days.map(d => (capify ? capitalize(d) : d)));

// TODO: sc-79421 Should accept an enum for days instead of arbitrary strings
// ex: formatDaysRollup(['sunday', 'monday', 'tuesday', 'wednesday', 'saturday'], true) => 'Sunday - Wednesday, Saturday'
export const formatDaysRollup = (days: HintedString<WeekDay>[], capify: boolean = false) => {
  if (!days) return '';

  const sortedDays = days.map(d => dayMap[d.toLocaleLowerCase() as LowercaseWeekDay]).sort().map(i => allDays[i]);
  const daysRollup: string[][] = [];
  let currRollup: LowercaseWeekDay[] = [];
  sortedDays.forEach((day) => {
    if (day) {
      const dayIdx = dayMap[day];
      const prevDay = currRollup[currRollup.length - 1];
      if (currRollup.length === 0 || (prevDay != null && dayIdx === dayMap[prevDay] + 1)) {
        currRollup.push(day);
      } else {
        daysRollup.push(formatDays(currRollup, capify));
        currRollup = [day];
      }
    }
  });
  if (currRollup.length) daysRollup.push(formatDays(currRollup, capify));

  return daysRollup.map((dr) => {
    if (dr.length > 2) {
      return `${dr[0]} - ${dr[dr.length - 1]}`;
    } else {
      return dr.join(', ');
    }
  }).join(', ');
};

export const calculateLastSevenDays = (dateRange: number) => {
  const today = new Date();
  const yesterday = subDays(today, 1);
  const sevenDaysAgo = subDays(today, 7);
  const sevenDays = eachDayOfInterval({ end: yesterday, start: sevenDaysAgo });

  const formattedSevenDays = sevenDays.map((day) => {
    let label = format(day, 'eeee');
    const value = format(day, 'eeee');
    if (dateRange === 7) {
      if (format(day, 'eeee') === format(today, 'eeee')) {
        label = `Last ${format(day, 'eeee')}`;
      }

      if (format(day, 'eeee') === format(yesterday, 'eeee')) {
        label = 'Yesterday';
      }
    }

    return { label, value };
  }).reverse();

  return formattedSevenDays;
};

/* eslint-disable sort-keys */
export const getFullDayName = (shortDayName: string) => {
  const days: Record<string, string> = {
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday',
  };

  return days[shortDayName] || '';
};
/* eslint-enable sort-keys */

const formatTimeUnit = (value: number, unit: string) => `${value} ${unit}${value === 1 ? '' : 's'}`;

export const convertSecondsToTime = (seconds: number) => {
  if (seconds >= 3600) {
    const hours = Math.floor(seconds / 3600);
    return formatTimeUnit(hours, 'hr');
  } else if (seconds >= 60) {
    const minutes = Math.floor(seconds / 60);
    return formatTimeUnit(minutes, 'min');
  } else {
    return formatTimeUnit(parseFloat(seconds.toFixed(2)), 'sec');
  }
};

export const timeFromSeconds = (seconds: Optional<number>, displayFormat = 'h:mm a') => format(addSeconds(startOfToday(), seconds || 0), displayFormat);

export const translateToUtc = (date: Date) => new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

export const translateToNormalizedUtc = (date: Date) => new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);

export const areDatesEqual = (date1: DirtyDate, date2: DirtyDate) => {
  const parsed1 = parseDate(date1);
  const parsed2 = parseDate(date2);
  return parsed1 == null || parsed2 == null ?
    parsed1 === parsed2 :
    isEqual(parsed1, parsed2);
};

export const countdownTimerFromSeconds = (seconds: number) => timeFromSeconds(seconds, seconds >= 3600 ? 'H:mm:ss' : 'mm:ss');

export const formatTimeWithTimezone = (date: Date | number | string, timezone: string, displayFormat = 'MMM d h:mm a z') => formatInTimeZone(date, timezone, displayFormat);

// Groups and sorts sent at or scheduled at times
export const groupDateByTimezone = (record: { locationTimezones: string[] | null }, value: string | number | Date) => {
  if (record.locationTimezones && record.locationTimezones.length > 0) {
    let dateGroups: string[] = [];
    const scheduledTimes = record.locationTimezones.filter(lt => lt).map((l) => {
      const time = formatInTimeZone(value, l, 'h:mm a z');
      const date = formatInTimeZone(value, l, 'M/d/yyyy');
      const sortingDate = formatInTimeZone(value, l, 'MMM d h:mm a');
      dateGroups.push(date);
      return ({
        date,
        sortingDate, // Set date with time to sort
        time,
      });
    }).sort((a, b) => new Date(a.sortingDate).getTime() - new Date(b.sortingDate).getTime());

    dateGroups = [...new Set(dateGroups)].sort();
    return dateGroups.map(group => (
      {
        group,
        times: scheduledTimes.filter(st => st.date === group).map(st => st.time),
      }
    ));
  }
  return null;
};

// returns the closest date before the given date that is a Monday
// if the given date is a Monday, it returns a copy of the date object
export const dateOfPreviousMonday = (date: Date): Date => {
  const dayIndex = date.getDay(); // days are 0-6, 0 is Sunday
  const previousMondayOffset = dayIndex === 0 ? 6 : dayIndex - 1;
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() - previousMondayOffset);
};
// returns the closest date after the given date that is a Sunday
// if the given date is a Sunday, it returns a copy of the date object
export const dateOfNextSunday = (date: Date): Date => {
  const dayIndex = date.getDay(); // days are 0-6, 0 is Sunday
  const nextSundayOffset = dayIndex === 0 ? 0 : 7 - dayIndex;
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + nextSundayOffset);
};

const weeksArray = (beginDate: Date, finishDate: Date, options: { fullWeeks?: boolean, dateFormat?: string | null, futureWeeks?: boolean }): Date[][] | string[][] => {
  const { fullWeeks, dateFormat, futureWeeks } = { dateFormat: null, fullWeeks: true, futureWeeks: false, ...options };
  let startDate = beginDate;
  let endDate = finishDate;

  if (!futureWeeks) {
    const rawDateToday = new Date();
    const dateToday = new Date(rawDateToday.toDateString()); // strip time from today's date
    endDate = (endDate <= dateToday ? endDate : dateToday);
  }
  if (fullWeeks) {
    startDate = dateOfPreviousMonday(startDate); // Monday on or before the startDate
    endDate = dateOfNextSunday(endDate); // Sunday on or after endDate, will move forward for full weeks even if futureWeeks is false
  }
  const weeks: Date[][] = [];
  while (startDate <= endDate) {
    let weekEndDate = dateOfNextSunday(startDate);
    if (!fullWeeks && weekEndDate > endDate) {
      weekEndDate = endDate;
    }
    weeks.push([startDate, weekEndDate]);
    startDate = new Date(weekEndDate.getFullYear(), weekEndDate.getMonth(), weekEndDate.getDate() + 1);
  }
  if (dateFormat) { // dateFormat of null returns the Date objects unprocessed
    return weeks.map(week => week.map(day => format(day, dateFormat)));
  }
  return weeks;
};

// returns an array of dates for a the weeks fully encompassing a month or date range
// each pair of dates represents the start and end of a week
// (i.e. the first week of the month may have days from the previous month and the last week of the month may have days from the next month)
// weeks start on Monday, not Sunday
// fullWeeks:
//   true => return all weeks that have at least one day in the month
//   false => return fractional weeks at the beginning and end of the month
// futureWeeks:
//   true => returns all weeks for the month regardless of current date
//   false => returns all weeks that are complete or in progress (i.e. the current week)
export const weeksForMonth = (date: string | Date, options: { fullWeeks?: boolean, dateFormat?: string | null, futureWeeks?: boolean }): Date[][] | string[][] => {
  let baseDate = new Date();

  const parsedDate = parseDate(date);
  if (parsedDate) baseDate = parsedDate;

  const startDate = new Date(baseDate.getFullYear(), baseDate.getMonth(), 1); // first day of the month
  const endDate = new Date(baseDate.getFullYear(), baseDate.getMonth() + 1, 0); // last day of the month

  return weeksArray(startDate, endDate, { ...options });
};

export const weeksInRange = (startAt: string | Date, endAt: string | Date, options: { fullWeeks?: boolean, dateFormat?: string | null, futureWeeks?: boolean }): Date[][] | string[][] => {
  let startDate = new Date();
  let endDate = new Date();

  const parsedDate = parseDate(startAt);
  if (parsedDate) startDate = parsedDate;
  const parsedEndDate = parseDate(endAt);
  if (parsedEndDate) endDate = parsedEndDate;

  return weeksArray(startDate, endDate, { ...options });
};

export const timeOptionsForDay = (interval: number): Date[] => {
  const startDate = startOfDay(new Date()); // today's start of day
  const endDate = endOfDay(new Date()); // today's end of day

  const times: Date[] = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    times.push(currentDate);
    currentDate = addMinutes(currentDate, interval);
  }
  return times;
};

export const toLocalDateAndTime = (isoTimeString: string) => {
  const utcTime = new Date(isoTimeString);

  return `${utcTime?.toDateString()} - ${utcTime?.toLocaleTimeString()}`;
};
