import React, { useMemo, type ReactNode } from 'react';
import { createBrowserHistory, type History } from 'history';
import { Router } from 'react-router';
import { executeWithProgressBar } from '~/utils/postponed';

interface AnimatedBrowserRouterProps {
  children: ReactNode;
}

// Performance Optimization: Improve Web Vitals INP by displaying a progress bar during navigation.
// React re-rendering can take seconds on slower devices, leaving the page unresponsive during this long JS task.
// Adding a progress bar indicates to users that the page is responding to their interactions (links are active).
export const AnimatedBrowserRouter = ({ children }: AnimatedBrowserRouterProps) => {
  const history = useMemo<History>(() => {
    const h = createBrowserHistory();
    const originalPush = h.push.bind(h);

    // CSS animations can't start until the current JavaScript task completes.
    // |executeWithProgressBar| defers React re-rendering, allowing the CSS animation to begin.
    h.push = (...args: Parameters<History['push']>) => executeWithProgressBar(() => {
      originalPush(...args); // Will initiate React re-rendering (long JS task).
    });

    return h;
  }, []);

  return <Router history={history}>{children}</Router>;
};
