import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { captureSentryMessage } from '~/utils/sentry';

let idCounter = 0;
function useNodeId() {
  const id = useMemo(() => {
    idCounter += 1;
    return idCounter;
  }, []);

  useEffect(() => {
    onMount(id);
    return () => onUnmount(id);
  }, [id]);

  return id;
}

enum RenderingTreeItemType {
  Heading,
  LevelProvider,
}

type TreeNode = HeadingTreeNode | LevelProviderTreeNode;

type BaseTreeNode = {
  ancestors: LevelProviderTreeNode[];
  id: number;
  ref: React.RefObject<HTMLElement>;
}

type HeadingTreeNode = {
  type: RenderingTreeItemType.Heading;
  setLevel?: (level: number) => void;
  level?: number;
  isVisible: boolean;
} & BaseTreeNode;

type LevelProviderTreeNode = {
  type: RenderingTreeItemType.LevelProvider;
  children: TreeNode[];
  recalculateHeadingLevels: () => void;
} & BaseTreeNode;

export const ParentIdContext = createContext<number>(-1);

const nodesMap = new Map<number /* TreeNode.id */, TreeNode>();

function getFirstDescendantHeading(levelProvider: LevelProviderTreeNode): HeadingTreeNode | undefined {
  for (const child of levelProvider.children) {
    if (child.type === RenderingTreeItemType.Heading) {
      if (child.isVisible) {
        return child;
      }
    } else {
      const descendantHeading = getFirstDescendantHeading(child);
      if (descendantHeading) {
        return descendantHeading;
      }
    }
  }
  return undefined;
}

function isLastRenderedNode(node: TreeNode): boolean {
  for (let i = 0; i < node.ancestors.length; i += 1) {
    const currentNode = i === 0 ? node : node.ancestors[i - 1];
    const levelProvider = node.ancestors[i];
    if (levelProvider && currentNode !== levelProvider.children[levelProvider.children.length - 1]) {
      return false;
    }
  }
  return true;
}

function getFirstAscendingHeading(heading: HeadingTreeNode): HeadingTreeNode | undefined {
  for (let i = 1; i < heading.ancestors.length; i += 1) {
    const node = heading.ancestors[i - 1];
    const levelProvider = heading.ancestors[i];
    if (!node || !levelProvider) {
      captureSentryMessage('Accessible Heading: node or levelProvider is null');
      return undefined;
    }
    const nodeIndex = levelProvider.children.indexOf(node);
    if (nodeIndex > 0) {
      const prevNode = levelProvider.children[nodeIndex - 1];
      if (prevNode?.type === RenderingTreeItemType.Heading && prevNode.isVisible) {
        return prevNode;
      }
    }
  }
  return undefined;
}

// Find one of the previous headings and determine the level of this heading in relation to the previous one.
// See spec/cypress/component/consumer/shared/AccessibleHeading.cy.tsx
function calculateHeadingLevel(heading: HeadingTreeNode): number {
  if (!heading.isVisible) {
    return heading.level || 1;
  }

  const lastAncestor = heading.ancestors[heading.ancestors.length - 1];
  if (!lastAncestor) {
    captureSentryMessage('Accessible Heading: lastAncestor is null');
    return 1;
  }

  const referenceHeading = getFirstAscendingHeading(heading) || getFirstDescendantHeading(lastAncestor);

  if (referenceHeading && referenceHeading !== heading) {
    if (referenceHeading.level === undefined) {
      captureSentryMessage('Accessible Heading: referenceHeading.level is undefined');
      return 1;
    }

    return referenceHeading.level + 1;
  }

  return 1;
}

// Visit every heading starting from the first one.
// The first heading is always h1.
// Each next heading will get its level in relation to the previous one
// depending on the nesting level.
function recalculateHeadingLevelsRecursively(node: LevelProviderTreeNode) {
  for (const child of node.children) {
    if (child.type === RenderingTreeItemType.Heading) {
      const level = calculateHeadingLevel(child);
      if (child.level !== level) {
        child.setLevel?.(level);
      }
    } else {
      recalculateHeadingLevelsRecursively(child);
    }
  }
}

// React doesn't provide means to detect the order of rendered children.
// Consider the Component:
//
// const Parent = () => {
// const [firstChildVisible, setFirstChildVisible] = useState(false);
// useEffect(() => {
//   setFirstChildVisible(true);
// }, []);
// return (
//   <div>
//     {firstChildVisible && <Child1 />}
//     <Child2 />
//   </div>
// );
//
// In this component  `Child2` will be rendered before `Child1` and it will be impossible to detect this fact using React.
// `verifyChildrenOrder` will detect the order of rendered children and recalculate heading levels if necessary.
function verifyChildrenOrder(node: LevelProviderTreeNode) {
  const sort = () => {
    node.children.sort((a, b) => {
      if (a.ref.current == null || b.ref.current == null) {
        return 0;
      }
      const compareResult = a.ref.current.compareDocumentPosition(b.ref.current);
      if (compareResult & Node.DOCUMENT_POSITION_FOLLOWING) {
        return -1;
      } else if (compareResult & Node.DOCUMENT_POSITION_PRECEDING) {
        return 1;
      }
      return 0;
    });
  };

  for (let i = 0; i < node.children.length - 1; i += 1) {
    const a = node.children[i];
    const b = node.children[i + 1];
    if (a?.ref.current && b?.ref.current) {
      const compareResult = a.ref.current.compareDocumentPosition(b.ref.current);
      if (!(compareResult & Node.DOCUMENT_POSITION_FOLLOWING)) {
        sort();
        node.recalculateHeadingLevels();
        return;
      }
    }
  }
}

// Detects if the element is hidden by CSS.
// If heading is hidden, then increasing the corresponding heading level will result in an Accessibility Issue.
function useRefDisplayed(ref: React.RefObject<HTMLElement> | undefined) {
  // It's not possible to get applied styles during the hydration because getComputedStyle is not
  // available before 'ref' is mounted. That's why the hook returns 'true' initially.
  const [isDisplayed, setIsDisplayed] = useState(true);
  useEffect(() => {
    if (!ref?.current) return;

    const style = window.getComputedStyle(ref.current);
    if (style.display === 'none' || ref.current.offsetParent === null) {
      setIsDisplayed(false);
    }
  }, [ref]);

  return isDisplayed;
}

function onMount(id: number): void {
  const node = nodesMap.get(id);
  if (!node) return;

  const parent = node.ancestors[0];
  if (parent) {
    verifyChildrenOrder(parent);
  }
}

function onUnmount(id: number): void {
  const node = nodesMap.get(id);
  if (!node) return;
  nodesMap.delete(id);

  const parent = node.ancestors[0];
  if (parent) {
    parent.children = parent.children.filter(child => child.id !== id);
    parent.recalculateHeadingLevels();
  }
}

let headingOverrideForTests: 1 | 2 | 3 | 4 | 5 | 6 | null = null;
/** @internal used by tests */
export function setHeadingLevelForTests(level: 1 | 2 | 3 | 4 | 5 | 6 | null) {
  headingOverrideForTests = level;
}

// This hook is used to calculate the heading level in relation to previous headings.
// It will search for the most relevant higher-order heading in the tree and count
// the number of levels between them.
export function useAccessibleHeadingLevel(ref: React.RefObject<HTMLElement>, skip: boolean = false) {
  const isVisible = useRefDisplayed(ref) && !skip;

  const parentId = useContext(ParentIdContext);

  const id = useNodeId();
  if (parentId === -1) {
    throw new Error('Accessible Headings must be used inside AHLevelProvider');
  }

  const parent = nodesMap.get(parentId) as LevelProviderTreeNode | undefined;

  // True for the first render only.
  if (!nodesMap.has(id)) {
    const newHeading: HeadingTreeNode = {
      ancestors: parent ? [parent, ...parent.ancestors] : [],
      id,
      isVisible,
      ref,
      type: RenderingTreeItemType.Heading,
    };

    nodesMap.set(id, newHeading);
    if (parent) {
      parent.children.push(newHeading);
      // In most cases headings are added to the end of the tree and we don't need to recalculate levels.
      // However, some headings can be added to the middle of the tree. Since heading levels depend on each other
      // we need to recalculate levels.
      if (!isLastRenderedNode(newHeading)) {
        parent.recalculateHeadingLevels();
      }
    }
  }

  const heading = nodesMap.get(id) as HeadingTreeNode;

  if (heading.isVisible !== isVisible) {
    heading.isVisible = isVisible;
    parent?.recalculateHeadingLevels();
  }

  const [level, setLevel] = useState(() => calculateHeadingLevel(heading));

  if (headingOverrideForTests !== null) {
    return headingOverrideForTests;
  }

  heading.setLevel = setLevel;
  heading.level = level;

  return (level > 6 || level < 1) ? 6 : level as 1 | 2 | 3 | 4 | 5 | 6;
}

// Keeps track of rendered children. Used to calculate heading levels in `useAccessibleHeadingLevel`.
// Level Providers are passive and don't cause re-renders themselves.
// `useAccessibleHeadingLevel` will count nested Level Providers and assign levels accordingly.
export function useLevelProviderId(ref: React.RefObject<HTMLDivElement>): number {
  const parentId = useContext(ParentIdContext);
  const id = useNodeId();

  // True for the first render only.
  if (!nodesMap.has(id)) {
    const parent = nodesMap.get(parentId) as LevelProviderTreeNode | undefined;
    const newLevelProvider: LevelProviderTreeNode = {
      ancestors: [],
      children: [],
      id,
      recalculateHeadingLevels: parent?.recalculateHeadingLevels || debounce(() => recalculateHeadingLevelsRecursively(newLevelProvider)),
      ref,
      type: RenderingTreeItemType.LevelProvider,
    };
    nodesMap.set(id, newLevelProvider);

    if (parent) {
      newLevelProvider.ancestors = [parent, ...parent.ancestors];
      parent.children.push(newLevelProvider);
    }
  }
  return id;
}
