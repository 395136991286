export default theme => ({
  announcementModalPaper: {
    padding: '0 !important',
  },
  bannerAnnouncementContainer: {
    '& + .pm-root-announcement': {
      display: 'none',
    },
    '& :first-child': {
      flexGrow: 1,
    },
    backgroundColor: theme.consumer.nav.rootAnnouncement.backgroundColor,
    display: 'flex',
    overflow: 'hidden',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: 'relative',
    width: '100%',
    zIndex: 100,
  },
  bannerAnnouncementDismissButton: {
    alignSelf: 'center',
    color: theme.consumer.nav.rootAnnouncement.color,
  },
  navbarCollapseSplit: {
    width: '100%',
  },
  navbarHeading: {
    whiteSpace: 'nowrap',
  },
  navItemWrapper: {
    display: 'flex',
    whiteSpace: 'noWrap',
  },
  profileButton: {
    '& .user-profile-avatar': {
      maxWidth: '26px',
    },
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    float: 'right',
    margin: theme.spacing(1, 2),
    padding: theme.spacing(0.5, 0),
  },
  rootAnnouncementContainer: {
    '& + .pm-root-announcement': {
      display: 'none',
    },
    alignItems: 'center',
    backgroundColor: theme.consumer.nav.rootAnnouncement.backgroundColor,
    display: 'flex',
    overflow: 'hidden',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: 'relative',
    width: '100%',
    zIndex: 100,
  },
  rootAnnouncementDismissButton: {
    color: theme.consumer.nav.rootAnnouncement.color,
  },
  rootAnnouncementLink: {
    '&:focus': {
      color: theme.consumer.nav.rootAnnouncement.color,
    },
    '&:hover': {
      color: theme.consumer.nav.rootAnnouncement.color,
      opacity: 0.7,
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
    color: theme.consumer.nav.rootAnnouncement.color,
    display: 'block',
    flexGrow: 1,
    marginRight: theme.spacing(4),
    minHeight: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    textAlign: 'center',
    textDecoration: 'underline',
  },
  rootAnnouncementText: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
    color: theme.consumer.nav.rootAnnouncement.color,
    display: 'block',
    marginRight: theme.spacing(4),
    minHeight: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
});
