import React from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { classNames, withStyles } from '../../../utils/withStyles';
import textBoxSectionStyles from './styles';
import CustomImg from '../../../shared/CustomImg';
import CustomContent from '../../shared/CustomContent';
import SectionAboutLink from '../SectionAboutLink';
import { AH, AHLevelProvider } from '../../shared/AccessibleHeading';

const TextBoxSection = (props) => {
  const {
    aboutHeading,
    aboutContent,
    aboutContentMaxWidth,
    aboutFontAlignment,
    aboutFontColor,
    aboutPadding,
    aboutPhotoAlt,
    aboutPhotoPosition,
    aboutPhotoUrl,
    classes,
    id,
    showTextBoxLink,
  } = props;

  const contentStyle = React.useMemo(() => ({
    color: aboutFontColor,
  }), [aboutFontColor]);

  return (
    <div
      className={classNames('pm-about-section', classes.container, classes[`container-max-width-${aboutContentMaxWidth}`])}
      style={{
        color: aboutFontColor,
        padding: `${parseInt(aboutPadding || '0', 10)}px ${parseInt(aboutPadding || '0', 10)}px`,
        textAlign: aboutFontAlignment,
      }}
    >
      <div className={classNames(classes.content, classes[`content-img-position-${aboutPhotoPosition}`])}>
        {aboutPhotoUrl && (
          <div className={classNames(classes.imgWrapper, classes[`imgWrapper-img-position-${aboutPhotoPosition}`], 'pm-about-section-image-container')}>
            <CustomImg
              className={classes.img}
              alt={aboutPhotoAlt || aboutHeading}
              size="sm"
              src={aboutPhotoUrl}
            />
          </div>
        )}
        <div className={classNames(classes.paragraph, 'pm-about-section-content-container')}>
          {aboutHeading && (
            <AH
              variant="h3"
              className={classes.header}
              style={{
                color: aboutFontColor,
                textAlign: aboutFontAlignment,
              }}
            >
              {aboutHeading}
            </AH>
          )}
          <AHLevelProvider>
            {aboutContent && (
              <div className="fr-view">
                <section className="pm-about-section-content" style={contentStyle}>
                  <CustomContent id={`pm-custom-section-${id}`} html={aboutContent} />
                </section>
              </div>
            )}
            {
              showTextBoxLink && <SectionAboutLink {...props} />
            }
          </AHLevelProvider>
        </div>
      </div>
    </div>
  );
};

TextBoxSection.defaultProps = {
  aboutContent: null,
  aboutFontColor: null,
  aboutHeading: null,
  aboutLinkText: null,
  aboutLinkUrl: null,
  aboutPadding: null,
  aboutPhotoAlt: null,
  aboutPhotoUrl: null,
};

TextBoxSection.propTypes = {
  aboutContent: PropTypes.string,
  aboutContentMaxWidth: PropTypes.string.isRequired,
  aboutFontAlignment: PropTypes.string.isRequired,
  aboutFontColor: PropTypes.string,
  aboutHeading: PropTypes.string,
  aboutLinkText: PropTypes.string,
  aboutLinkUrl: PropTypes.string,
  aboutPadding: PropTypes.string,
  aboutPhotoAlt: PropTypes.string,
  aboutPhotoPosition: PropTypes.string.isRequired,
  aboutPhotoUrl: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(textBoxSectionStyles),
)(TextBoxSection);
