// See docs/loadable-components.md for details regarding **.imports-X.** file extension & duplicate file structure.
import loadable from '@loadable/component';
import retry from '../utils/retry';

export const BlogPostPage = loadable(() => retry(() => import('./blog/BlogPostPage')));
export const BlogPostIndexPage = loadable(() => retry(() => import('./blog/BlogPostIndexPage')));
export const CalendarEventPage = loadable(() => retry(() => import('./calendar_event/CalendarEventPage')));
export const CurbsideArrive = loadable(() => retry(() => import('./online_ordering/curbside/CurbsideArrive')));
export const DineInOrderingPage = loadable(() => retry(() => import('./online_ordering/dine_in/DineInOrderingPage/DineInOrderingPage')));
export const MenuItemPage = loadable(() => retry(() => import('./menus/MenuItemPage')));
export const MenuItemCartReviewPage = loadable(() => retry(() => import('./reviews/MenuItemCartReviewPage')));
export const MenuPage = loadable(() => retry(() => import('./menus/MenuPage')));
export const OfferPage = loadable(() => retry(() => import('./offers/OfferPage')));
export const OrderingEventOrderPage = loadable(() => retry(() => import('./ordering_event/OrderingEventOrderPage')));
export const OrderingEventPage = loadable(() => retry(() => import('./ordering_event/OrderingEventPage')));
export const OrderingPreviewPage = loadable(() => retry(() => import('./ordering_preview/OrderingPreviewPage')));
export const ConfirmationPage = loadable(() => retry(() => import('./orderConfirmation/ConfirmationPage')));
export const PreviewPage = loadable(() => retry(() => import('./preview/PreviewPage')));
export const ResetPage = loadable(() => retry(() => import('./reset/ResetPage')));
export const ResetTokenPage = loadable(() => retry(() => import('./reset/ResetTokenPage')));
export const ReviewsPage = loadable(() => retry(() => import('./reviews/ReviewsPage')));
export const TermsPage = loadable(() => retry(() => import('./terms/TermsPage')));
export const WaitlistPage = loadable(() => retry(() => import('./waitlist/WaitlistPage')));
export const WaitlistLinePage = loadable(() => retry(() => import('./waitlist/WaitlistLine')));
export const ReservationsPage = loadable(() => retry(() => import('./reservations/ReservationsPage')));
export const FeedbackPage = loadable(() => retry(() => import(/* webpackChunkName: "FeedbackPage" */ './FeedbackPage')));
export const FeedbackThankyouPage = loadable(() => retry(() => import(/* webpackChunkName: "FeedbackPage" */ './FeedbackThankyouPage')));
