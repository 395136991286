import type { ValueOf } from 'type-fest';

// `NotYetTyped` is similar to `unknown` but you can't assign known types to it.
// The benefit over `unknown` is `NotYetTyped` forces you to update
// itself when you add new types to the project.
//
// // OK:
// const a: NotYetTyped = 1 as any;

// // Not OK:
// const b: NotYetTyped = '1';
// const c: NotYetTyped = 1;
// const d: NotYetTyped = { a: 1 };
// const e: NotYetTyped = {};
export type NotYetTyped = {
  __notYetTyped: never;
}

export const CartType = {
  Catering: 'catering_cart_type',
  Default: 'default_cart_type',
  DineIn: 'dine_in_cart_type',
} as const;
export type CartType = ValueOf<typeof CartType>;

export const FulfillmentType = {
  Delivery: 'delivery_fulfillment_type',
  DineIn: 'dine_in_fulfillment_type',
  Pickup: 'pickup_fulfillment_type',
} as const;
export type FulfillmentType = ValueOf<typeof FulfillmentType>;

export const MenuType = {
  Aloha: 'menu_type_aloha',
  Build: 'menu_type_build',
  Clover: 'menu_type_clover',
  Import: 'menu_type_import',
  Micros: 'menu_type_micros',
  NcrSilver: 'menu_type_ncr_silver',
  Square: 'menu_type_square',
  Toast: 'menu_type_toast',
} as const;

// These should match BE model names (e.g: ToastRestaurant::ACCOUNT_TYPE)
export const AccountType = {
  Aloha: 'NcrAloha',
  Authorize: 'Authorize',
  Clover: 'Clover',
  Deliverect: 'Deliverect',
  Everyware: 'Everyware',
  Heartland: 'Heartland',
  Micros: 'Micros',
  NcrPayment: 'NcrPayment',
  NcrSilver: 'NcrSilver',
  Square: 'Square',
  Stripe: 'Stripe',
  Toast: 'Toast',
} as const;
export type AccountType = ValueOf<typeof AccountType>;

export const TermsModalType = {
  Accessibility: 'accessibility',
  ClientTerms: 'clientTerms',
  Giveaway: 'giveaway',
  Privacy: 'privacy',
};
export type TermsModalType = ValueOf<typeof TermsModalType>;

export const NotifyState = {
  Added: 'added_notify_state',
  Cancelled: 'cancelled_notify_state',
  Next: 'next_notify_state',
  Ready: 'ready_notify_state',
  Updated: 'updated_notify_state',
} as const;

export const PartyEntryPoint = {
  Answering: 'answering_entry_point',
  Host: 'host_entry_point',
  QRCode: 'qr_code_entry_point',
  Website: 'website_entry_point',
} as const;

export const PartyState = {
  Cancelled: 'cancelled_party_state',
  Pending: 'pending_party_state',
  Reserved: 'reserved_party_state',
  Seated: 'seated_party_state',
  Waiting: 'waiting_party_state',
} as const;

export const ReservationPartyState = {
  Accepted: 'reserved_party_state',
  Cancelled: 'cancelled_party_state',
  Pending: 'pending_party_state',
  Rejected: 'rejected_party_state',
  Seated: 'seated_party_state',
} as const;

export const ReservationsNotifyState = {
  Accepted: 'reserved_notify_state',
  Rejected: 'rejected_notify_state',
} as const;

export const PartyType = {
  Reservation: 'reservation_party_type',
  Waitlist: 'waitlist_party_type',
} as const;

export const WoflowImportState = {
  Cancelled: 'cancelled',
  Completed: 'completed',
  Created: 'created',
  Failed: 'failed',
  Pending: 'pending',
  Processed: 'processed',
  Submitted: 'submitted',
} as const;

export const MenuLayout = {
  Card: 'card',
  ListLayout: 'list_layout',
  NextLayout: 'next_layout',
  Simple: 'simple',
  SimpleTwo: 'simple_two',
} as const;
export const allMenuLayouts = Object.values(MenuLayout);
export type MenuLayoutType = ValueOf<typeof MenuLayout>;
