import type { Dispatch } from 'redux';
import type { AccountType, TermsModalType } from '../utils/types';

export const OPEN_FORGOT_MODAL = 'OPEN_FORGOT_MODAL';
export const CLOSE_FORGOT_MODAL = 'CLOSE_FORGOT_MODAL';
export const OPEN_TERMS_MODAL = 'OPEN_TERMS_MODAL';
export const CLOSE_TERMS_MODAL = 'CLOSE_TERMS_MODAL';
export const OPEN_NAV_MODAL = 'OPEN_NAV_MODAL';
export const CLOSE_NAV_MODAL = 'CLOSE_NAV_MODAL';
export const OPEN_PROFILE_MODAL = 'OPEN_PROFILE_MODAL';
export const CLOSE_PROFILE_MODAL = 'CLOSE_PROFILE_MODAL';
export const OPEN_SOCIAL_SHARE_MODAL = 'OPEN_SOCIAL_SHARE_MODAL';
export const CLOSE_SOCIAL_SHARE_MODAL = 'CLOSE_SOCIAL_SHARE_MODAL';
export const OPEN_REVIEW_SECTION_MODAL = 'OPEN_REVIEW_SECTION_MODAL';
export const CLOSE_REVIEW_SECTION_MODAL = 'CLOSE_REVIEW_SECTION_MODAL';
export const OPEN_PAYMENT_ACCOUNT_MODAL = 'OPEN_PAYMENT_ACCOUNT_MODAL';
export const CLOSE_PAYMENT_ACCOUNT_MODAL = 'CLOSE_PAYMENT_ACCOUNT_MODAL';
export const OPEN_ACCOUNT_LOCATION_MODAL = 'OPEN_ACCOUNT_LOCATION_MODAL';
export const CLOSE_ACCOUNT_LOCATION_MODAL = 'CLOSE_ACCOUNT_LOCATION_MODAL';
export const OPEN_DINING_OPTIONS_MODAL = 'OPEN_DINING_OPTIONS_MODAL';
export const CLOSE_DINING_OPTIONS_MODAL = 'CLOSE_DINING_OPTIONS_MODAL';
export const OPEN_REVENUE_CENTERS_MODAL = 'OPEN_REVENUE_CENTERS_MODAL';
export const CLOSE_REVENUE_CENTERS_MODAL = 'CLOSE_REVENUE_CENTERS_MODAL';
export const OPEN_TOAST_ALTERNATIVE_PAYMENT_TYPE_MODAL = 'OPEN_TOAST_ALTERNATIVE_PAYMENT_TYPE_MODAL';
export const CLOSE_TOAST_ALTERNATIVE_PAYMENT_TYPE_MODAL = 'CLOSE_TOAST_ALTERNATIVE_PAYMENT_TYPE_MODAL';
export const OPEN_NCR_SILVER_CHARGE_OPTIONS_MODAL = 'OPEN_NCR_SILVER_CHARGE_OPTIONS_MODAL';
export const CLOSE_NCR_SILVER_CHARGE_OPTIONS_MODAL = 'CLOSE_NCR_SILVER_CHARGE_OPTIONS_MODAL';
export const OPEN_PAYMENT_PROVIDER_SELECT_MODAL = 'OPEN_PAYMENT_PROVIDER_SELECT_MODAL';
export const CLOSE_PAYMENT_PROVIDER_SELECT_MODAL = 'CLOSE_PAYMENT_PROVIDER_SELECT_MODAL';
export const OPEN_ORDER_TYPES_MODAL = 'OPEN_ORDER_TYPES_MODAL';
export const CLOSE_ORDER_TYPES_MODAL = 'CLOSE_ORDER_TYPES_MODAL';
export const OPEN_MICROS_ORDER_TYPES_MODAL = 'OPEN_MICROS_ORDER_TYPES_MODAL';
export const CLOSE_MICROS_ORDER_TYPES_MODAL = 'CLOSE_MICROS_ORDER_TYPES_MODAL';
export const OPEN_WEB_BUILDER_SETTINGS_MODAL = 'OPEN_WEB_BUILDER_SETTINGS_MODAL';
export const CLOSE_WEB_BUILDER_SETTINGS_MODAL = 'CLOSE_WEB_BUILDER_SETTINGS_MODAL';
export const OPEN_VIP_V2_MODAL = 'OPEN_VIP_V2_MODAL';
export const CLOSE_VIP_V2_MODAL = 'CLOSE_VIP_V2_MODAL';
export const OPEN_FOLLOW_PROMPT_V2 = 'OPEN_FOLLOW_PROMPT_V2';
export const CLOSE_FOLLOW_PROMPT_V2 = 'CLOSE_FOLLOW_PROMPT_V2';
export const SET_VIP_DATA = 'SET_VIP_DATA';
export const OPEN_PROFILE_V2_MODAL = 'OPEN_PROFILE_V2_MODAL';
export const CLOSE_PROFILE_V2_MODAL = 'CLOSE_PROFILE_V2_MODAL';
export const OPEN_USER_EMAIL_CHECK_PROMPT = 'OPEN_USER_EMAIL_CHECK_PROMPT';
export const CLOSE_USER_EMAIL_CHECK_PROMPT = 'CLOSE_USER_EMAIL_CHECK_PROMPT';
export const SET_DID_SUBMIT_EMAIL_WITH_FOLLOW_PROMPT = 'SET_DID_SUBMIT_EMAIL_WITH_FOLLOW_PROMPT';
export const ENABLE_WIDEN_SIDE_PANEL_SETTINGS_MODAL = 'ENABLE_WIDEN_SIDE_PANEL_SETTINGS_MODAL';
export const DISABLE_WIDEN_SIDE_PANEL_SETTINGS_MODAL = 'DISABLE_WIDEN_SIDE_PANEL_SETTINGS_MODAL';
export const OPEN_FAVORITE_LOCATION_MODAL = 'OPEN_FAVORITE_LOCATION_MODAL';
export const CLOSE_FAVORITE_LOCATION_MODAL = 'CLOSE_FAVORITE_LOCATION_MODAL';

type VIPData = {
  userEmail?: string | null,
  showSignInForm?: boolean,
  isPopPath?: boolean,
  isReviewPath?: boolean,
  isMagicLinkPath?: boolean,
  isWaitlistPath?: boolean,
  isBecomeVipModal?: boolean,
  isReservationPath?: boolean,
};

export type ModalAction =
  { type: typeof OPEN_FORGOT_MODAL } |
  { type: typeof CLOSE_FORGOT_MODAL } |
  { type: typeof OPEN_TERMS_MODAL, modalType: TermsModalType } |
  { type: typeof CLOSE_TERMS_MODAL } |
  { type: typeof OPEN_NAV_MODAL } |
  { type: typeof CLOSE_NAV_MODAL } |
  { type: typeof OPEN_PROFILE_MODAL } |
  { type: typeof CLOSE_PROFILE_MODAL } |
  { type: typeof OPEN_SOCIAL_SHARE_MODAL, socialShareMessage: string } |
  { type: typeof CLOSE_SOCIAL_SHARE_MODAL } |
  { type: typeof OPEN_REVIEW_SECTION_MODAL, locationId: number | null, menuItemId: number | null } |
  { type: typeof CLOSE_REVIEW_SECTION_MODAL } |
  { type: typeof OPEN_PAYMENT_ACCOUNT_MODAL } |
  { type: typeof CLOSE_PAYMENT_ACCOUNT_MODAL } |
  { type: typeof OPEN_ACCOUNT_LOCATION_MODAL, accountId: number, accountType: AccountType } |
  { type: typeof CLOSE_ACCOUNT_LOCATION_MODAL } |
  { type: typeof OPEN_DINING_OPTIONS_MODAL, locationId: number } |
  { type: typeof CLOSE_DINING_OPTIONS_MODAL } |
  { type: typeof OPEN_REVENUE_CENTERS_MODAL, locationId: number } |
  { type: typeof CLOSE_REVENUE_CENTERS_MODAL } |
  { type: typeof OPEN_TOAST_ALTERNATIVE_PAYMENT_TYPE_MODAL, locationId: number } |
  { type: typeof CLOSE_TOAST_ALTERNATIVE_PAYMENT_TYPE_MODAL } |
  { type: typeof OPEN_NCR_SILVER_CHARGE_OPTIONS_MODAL, locationId: number } |
  { type: typeof CLOSE_NCR_SILVER_CHARGE_OPTIONS_MODAL } |
  { type: typeof OPEN_PAYMENT_PROVIDER_SELECT_MODAL } |
  { type: typeof CLOSE_PAYMENT_PROVIDER_SELECT_MODAL } |
  { type: typeof OPEN_ORDER_TYPES_MODAL, cloverAccountId: number } |
  { type: typeof CLOSE_ORDER_TYPES_MODAL } |
  { type: typeof OPEN_MICROS_ORDER_TYPES_MODAL, locationId: number } |
  { type: typeof CLOSE_MICROS_ORDER_TYPES_MODAL } |
  { type: typeof OPEN_WEB_BUILDER_SETTINGS_MODAL } |
  { type: typeof CLOSE_WEB_BUILDER_SETTINGS_MODAL } |
  { type: typeof OPEN_VIP_V2_MODAL, isOnOloPage: boolean | null } |
  { type: typeof CLOSE_VIP_V2_MODAL } |
  { type: typeof OPEN_FOLLOW_PROMPT_V2 } |
  { type: typeof CLOSE_FOLLOW_PROMPT_V2 } |
  // TODO: set default value for isWaitlistPath
  { type: typeof SET_VIP_DATA, vipData: Omit<Required<VIPData>, 'isWaitlistPath'> & { isWaitlistPath: boolean | undefined } } |
  { type: typeof SET_VIP_DATA, vipData: Omit<Required<VIPData>, 'isBecomeVipModal'> & { isBecomeVipModal: boolean | undefined } } |
  { type: typeof SET_VIP_DATA, vipData: Omit<Required<VIPData>, 'isReservationPath'> & { isReservationPath: boolean | undefined } } |
  { type: typeof OPEN_PROFILE_V2_MODAL, setActiveProfilePage: string | null } |
  { type: typeof CLOSE_PROFILE_V2_MODAL } |
  { type: typeof OPEN_USER_EMAIL_CHECK_PROMPT } |
  { type: typeof CLOSE_USER_EMAIL_CHECK_PROMPT } |
  { type: typeof SET_DID_SUBMIT_EMAIL_WITH_FOLLOW_PROMPT, didSubmitEmailWithFollowPrompt: boolean } |
  { type: typeof ENABLE_WIDEN_SIDE_PANEL_SETTINGS_MODAL } |
  { type: typeof DISABLE_WIDEN_SIDE_PANEL_SETTINGS_MODAL } |
  { type: typeof OPEN_FAVORITE_LOCATION_MODAL } |
  { type: typeof CLOSE_FAVORITE_LOCATION_MODAL };

export function openForgotModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: OPEN_FORGOT_MODAL });
  };
}

export function closeForgotModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_FORGOT_MODAL });
  };
}

export function openTermsModal(modalType: TermsModalType) {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({
      modalType,
      type: OPEN_TERMS_MODAL,
    });
  };
}

export function closeTermsModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_TERMS_MODAL });
  };
}

export function openNavModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: OPEN_NAV_MODAL });
  };
}

export function closeNavModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_NAV_MODAL });
  };
}

export function openPaymentAccountModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: OPEN_PAYMENT_ACCOUNT_MODAL });
  };
}

export function closePaymentAccountModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_PAYMENT_ACCOUNT_MODAL });
  };
}

export function openAccountLocationModal(accountId: number, accountType: AccountType) {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({
      accountId,
      accountType,
      type: OPEN_ACCOUNT_LOCATION_MODAL,
    });
  };
}

export function closeAccountLocationModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_ACCOUNT_LOCATION_MODAL });
  };
}

export function openDiningOptionsModal(locationId: number) {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({
      locationId,
      type: OPEN_DINING_OPTIONS_MODAL,
    });
  };
}

export function closeDiningOptionsModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_DINING_OPTIONS_MODAL });
  };
}

export function openRevenueCentersModal(locationId: number) {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({
      locationId,
      type: OPEN_REVENUE_CENTERS_MODAL,
    });
  };
}

export function closeRevenueCentersModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_REVENUE_CENTERS_MODAL });
  };
}

export function openToastAlternativePaymentTypeModal(locationId: number) {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({
      locationId,
      type: OPEN_TOAST_ALTERNATIVE_PAYMENT_TYPE_MODAL,
    });
  };
}

export function closeToastAlternativePaymentTypeModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_TOAST_ALTERNATIVE_PAYMENT_TYPE_MODAL });
  };
}

export function openNcrSilverChargeOptionsModal(locationId: number) {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({
      locationId,
      type: OPEN_NCR_SILVER_CHARGE_OPTIONS_MODAL,
    });
  };
}

export function closeNcrSilverChargeOptionsModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_NCR_SILVER_CHARGE_OPTIONS_MODAL });
  };
}

export function openPaymentProviderSelectModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: OPEN_PAYMENT_PROVIDER_SELECT_MODAL });
  };
}

export function closePaymentProviderSelectModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_PAYMENT_PROVIDER_SELECT_MODAL });
  };
}

export function openOrderTypesModal(cloverAccountId: number) {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({
      cloverAccountId,
      type: OPEN_ORDER_TYPES_MODAL,
    });
  };
}

export function openMicrosOrderTypesModal(locationId: number) {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({
      locationId,
      type: OPEN_MICROS_ORDER_TYPES_MODAL,
    });
  };
}

export function closeMicrosOrderTypesModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_MICROS_ORDER_TYPES_MODAL });
  };
}

export function closeOrderTypesModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_ORDER_TYPES_MODAL });
  };
}

export function openProfileModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: OPEN_PROFILE_MODAL });
  };
}

export function closeProfileModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_PROFILE_MODAL });
  };
}

export function closeSocialShareModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_SOCIAL_SHARE_MODAL });
  };
}

export function openSocialShareModal(socialShareMessage: string) {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({
      socialShareMessage,
      type: OPEN_SOCIAL_SHARE_MODAL,
    });
  };
}

export function openReviewSectionModal(locationId: number | null = null, menuItemId: number | null = null) {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({
      locationId,
      menuItemId,
      type: OPEN_REVIEW_SECTION_MODAL,
    });
  };
}

export function closeReviewSectionModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_REVIEW_SECTION_MODAL });
  };
}

export function openWebBuilderSettingsModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: OPEN_WEB_BUILDER_SETTINGS_MODAL });
  };
}

export function closeWebBuilderSettingsModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_WEB_BUILDER_SETTINGS_MODAL });
  };
}

export function openVipV2Modal(isOnOloPage: boolean | null = null) {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({
      isOnOloPage,
      type: OPEN_VIP_V2_MODAL,
    });
  };
}

export function closeVipV2Modal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_VIP_V2_MODAL });
  };
}

export function openFollowPromptV2() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({
      type: OPEN_FOLLOW_PROMPT_V2,
    });
  };
}

export function closeFollowPromptV2() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_FOLLOW_PROMPT_V2 });
  };
}

export function setVipData({ userEmail = null, showSignInForm = false, isPopPath = false, isReviewPath = false, isMagicLinkPath = false, isWaitlistPath = false, isBecomeVipModal = false, isReservationPath = false }: VIPData = {}) {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({
      type: SET_VIP_DATA,
      vipData: {
        isBecomeVipModal,
        isMagicLinkPath,
        isPopPath,
        isReservationPath,
        isReviewPath,
        isWaitlistPath,
        showSignInForm,
        userEmail,
      },
    });
  };
}

export function openProfileV2Modal(setActiveProfilePage: string | null = null) {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({
      setActiveProfilePage,
      type: OPEN_PROFILE_V2_MODAL,
    });
  };
}

export function closeProfileV2Modal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_PROFILE_V2_MODAL });
  };
}

export function openUserEmailCheckPrompt() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({
      type: OPEN_USER_EMAIL_CHECK_PROMPT,
    });
  };
}

export function closeUserEmailCheckPrompt() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({
      type: CLOSE_USER_EMAIL_CHECK_PROMPT,
    });
  };
}

export function setDidSubmitEmailWithFollowPrompt(didSubmitEmailWithFollowPrompt = false) {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({
      didSubmitEmailWithFollowPrompt,
      type: SET_DID_SUBMIT_EMAIL_WITH_FOLLOW_PROMPT,
    });
  };
}

export function enableWidenSidePanelSettingsModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({
      type: ENABLE_WIDEN_SIDE_PANEL_SETTINGS_MODAL,
    });
  };
}

export function disableWidenSidePanelSettingsModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({
      type: DISABLE_WIDEN_SIDE_PANEL_SETTINGS_MODAL,
    });
  };
}

export function openFavoriteLocationModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: OPEN_FAVORITE_LOCATION_MODAL });
  };
}

export function closeFavoriteLocationModal() {
  return (dispatch: Dispatch<ModalAction>) => {
    dispatch({ type: CLOSE_FAVORITE_LOCATION_MODAL });
  };
}
