import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';
import { withRouter } from 'react-router-dom';
import { Icon } from '@popmenu/common-ui';
import { ArrowLeft, ArrowRight, ChevronDown, Edit, Pause, Play } from '@popmenu/web-icons';
import { connect } from 'react-redux';

import { isTransparentColor } from '../../utils/colors';
import { findElement, scrollTo, toggleClass } from '../../utils/dom';
import { runAfter } from '../../utils/postponed';
import { isTabKey } from '../../utils/events';
import { withWindowContext } from '../../shared/WindowProvider';
import { withWindowSizeContext } from '../../shared/WindowSizeProvider';
import { classNames, withStyles } from '../../utils/withStyles';
import { themeShape, withTheme } from '../../utils/withTheme';
import { withRestaurant } from '../../utils/withRestaurant';
import { withFeatureFlags } from '../../utils/withFeatureFlags';
import { visualRegressionsMode } from '../../utils/visualRegressionsMode';

import { AH } from './AccessibleHeading';
import CustomImg from '../../shared/CustomImg';
import CustomLink from './CustomLink';
import Stream from '../../shared/Stream';

const PHOTO_TIMEOUT_MS = 4500;

const headerStyles = theme => ({
  pauseButton: {
    background: 'none',
    border: 'none',
    borderRadius: theme.spacing(0.5),
    bottom: 0,
    boxSizing: 'border-box',
    color: 'white',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 1.75,
    minWidth: 'unset',
    padding: theme.spacing(1.5, 2),
    position: 'absolute',
    right: 0,
    zIndex: 1,
  },
  transitionTiming: props => ({
    '&.transition-fade': {
      transition: `opacity ${props.headerTransitionTiming}ms linear !important`,
    },
    '&.transition-slide': {
      '&.header-bg-hide': {
        transition: 'none !important',
      },
      '&.header-bg-prev': {
        left: '-100%',
        transition: ` left ${props.headerTransitionTiming}ms linear !important`,
      },
      transition: `left ${props.headerTransitionTiming}ms linear !important`,
    },
  }),
});

class Header extends React.PureComponent {
  static onSkipClick(e) {
    if (isTabKey(e)) {
      return;
    }
    const section = findElement('.pm-custom-section');
    if (section) {
      scrollTo(section);
      section.focus();
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      carouselPaused: false,
      isOO: false,
      loadedPhotosIndexes: new Set([0]),
      photoIndex: 0,
      // Photos loading is initially postponed until important assets are ready.
      photoLoadingAllowed: false,
      prevPhotoIndex: null,
      useObjectFit: true,
      videoPaused: false,
      videoStarted: false,
    };
    this.handleNextSlide = this.handleNextSlide.bind(this);
    this.handlePhotoTimeout = this.handlePhotoTimeout.bind(this);
    this.handlePrevSlide = this.handlePrevSlide.bind(this);
    this.pauseCarousel = this.pauseCarousel.bind(this);
    this.playCarousel = this.playCarousel.bind(this);
    this.sectionAction = this.sectionAction.bind(this);
    this.setVideoStarted = this.setVideoStarted.bind(this);
    this.toggleVideoPlayback = this.toggleVideoPlayback.bind(this);
    this.photoTimeout = null;
    this.onPhotoLoadTaskQueue = [];
  }

  componentDidMount() {
    // Determine if object-fit is supported by the browser
    this.setState({ useObjectFit: 'objectFit' in document.body.style });

    // Determine if header is on an online ordering page
    const currentCustomPage = this.props.restaurant.customPages.find(page => page.url === this.props.location.pathname);
    const isOLOPage = (currentCustomPage && (currentCustomPage.hasCateringSection || currentCustomPage.hasOnlineOrderingSection)) || this.props.location.pathname === '/dine-in';
    this.setState({ isOO: isOLOPage });

    runAfter('firstUserInteraction', () => this.initCarousel());
    this.mountTimeMs = Date.now();

    // Check if header has a BG image
    this.toggleBodyBackgroundClass();
  }

  componentDidUpdate() {
    this.toggleBodyBackgroundClass();
  }

  componentWillUnmount() {
    this.componentIsUnmount = true;
    clearTimeout(this.photoTimeout);
  }

  onPhotoLoad = (index) => {
    this.setState((prevState) => {
      if (prevState.loadedPhotosIndexes.has(index)) {
        return prevState;
      }
      const loadedPhotosIndexes = new Set(prevState.loadedPhotosIndexes);
      loadedPhotosIndexes.add(index);
      return { loadedPhotosIndexes };
    });

    while (this.onPhotoLoadTaskQueue.length > 0) {
      this.onPhotoLoadTaskQueue.shift()();
    }
  };

  async waitForPhotoLoad() {
    return new Promise((resolve) => {
      this.onPhotoLoadTaskQueue.push(resolve);
    });
  }

  async initCarousel() {
    if (this.state.photoLoadingAllowed || visualRegressionsMode) return;
    this.setState({ photoLoadingAllowed: true });

    // Will not play carousel until the photo is loaded.
    if (this.heroImages().length > 1) {
      await this.waitForPhotoLoad();
    }

    if (!this.componentIsUnmount && !this.state.carouselPaused) {
      const millisecondsFromMount = Date.now() - this.mountTimeMs;
      this.photoTimeout = setTimeout(
        this.handlePhotoTimeout,
        Math.max(0, PHOTO_TIMEOUT_MS - millisecondsFromMount),
      );
    }
  }

  toggleBodyBackgroundClass() {
    toggleClass(document.body, 'no-custom-page-bg', this.heroImages().length === 0 || (this.state.isOO && this.props.isMobile));
  }

  pauseCarousel() {
    clearTimeout(this.photoTimeout);
    this.setState({ carouselPaused: true });
  }

  playCarousel() {
    this.setState({ carouselPaused: false });
    if (!this.state.photoLoadingAllowed) return;
    clearTimeout(this.photoTimeout);
    this.photoTimeout = setTimeout(this.handlePhotoTimeout, PHOTO_TIMEOUT_MS);
  }

  handlePrevSlide() {
    this.setState(prevState => ({
      photoIndex: this.cancelndexOverflow(prevState.photoIndex - 1),
      photoLoadingAllowed: true,
      prevPhotoIndex: prevState.photoIndex,
    }));
  }

  handleNextSlide() {
    this.setState(prevState => ({
      photoIndex: this.cancelndexOverflow(prevState.photoIndex + 1),
      photoLoadingAllowed: true,
      prevPhotoIndex: prevState.photoIndex,
    }));
  }

  cancelndexOverflow(index) {
    const heroImages = this.heroImages();
    if (index >= heroImages.length) {
      return 0;
    } else if (index < 0) {
      return heroImages.length - 1;
    }
    return index;
  }

  // Build photo URL list from string, array, or video preview
  // Array of [{ alt, imageUrl }]
  heroImages() {
    if (this.props.heroImages && this.props.heroImages.length) {
      return this.props.heroImages;
    }
    if (this.props.photoUrl && this.props.photoUrl.length) {
      if (typeof this.props.photoUrl === 'string') {
        return [{ imageUrl: this.props.photoUrl }];
      }
      return this.props.photoUrl.map(photoUrl => ({ imageUrl: photoUrl }));
    }
    if (this.props.videoUrl) {
      return [{ imageUrl: this.props.videoThumbnailUrl || this.props.videoUrl.replace('.webm', '.jpg') }];
    }
    if (this.props.restaurant.homePage && this.props.restaurant.homePage.heroImages.length) {
      return this.props.restaurant.homePage.heroImages.map(h => ({ alt: h.headerImageAlt, imageUrl: h.imageUrl }));
    }
    return [];
  }

  // Rotate between photo URLs
  handlePhotoTimeout() {
    const nextIndex = this.cancelndexOverflow(this.state.photoIndex + 1);
    if (nextIndex !== this.state.photoIndex && this.state.loadedPhotosIndexes.has(nextIndex)) {
      this.handleNextSlide();
    }
    clearTimeout(this.photoTimeout);
    this.photoTimeout = setTimeout(this.handlePhotoTimeout, PHOTO_TIMEOUT_MS);
  }

  setVideoStarted() {
    if (this.state.videoStarted) {
      return;
    }
    this.setState({ videoStarted: true });
  }

  toggleVideoPlayback() {
    this.setState(prevState => ({ videoPaused: !prevState.videoPaused }));
  }

  renderVideo() {
    if (!this.props.videoCloudflareId) {
      return null;
    }
    return (
      <Stream
        aria-hidden
        autoplay={!this.state.videoPaused}
        cloudflareId={this.props.videoCloudflareId}
        id="pm-header-background-video"
        loop
        muted
        onStart={this.setVideoStarted}
        poster={this.props.videoThumbnailUrl}
      />
    );
  }

  renderVideoPauseButton() {
    const buttonHidden = (
      // Display control when renderBackground and renderVideo criteria are met
      this.heroImages().length === 0 ||
      !this.props.videoUrl ||
      !this.props.videoCloudflareId ||
      // Display control after video has initially buffered
      !this.state.videoStarted
    );
    if (buttonHidden) {
      return null;
    }
    // Avoid using component library Button as to prevent styling conflicts
    return (
      <button
        aria-controls="pm-header-background-video"
        aria-label={this.state.videoPaused ? 'Resume background video' : 'Pause background video'}
        className={classNames(this.props.classes.pauseButton, 'pm-header-video-control')}
        onClick={this.toggleVideoPlayback}
        type="button"
      >
        <Icon
          icon={this.state.videoPaused ? Play : Pause}
          size="medium"
        />
      </button>
    );
  }

  renderBackground() {
    let heroImages = this.heroImages();
    const style = {
      height: this.state.useObjectFit ? undefined : 'auto',
    };
    if (this.props.backgroundColor) {
      style.backgroundColor = this.props.backgroundColor;
    }
    // No background
    if (heroImages.length === 0) {
      return (
        <div
          className="pm-header-bg header-bg bg-cover"
          style={style}
        />
      );
    }
    // Video background
    if (this.props.videoUrl) {
      return (
        <div
          className="pm-header-bg header-bg bg-cover"
          style={style}
        >
          {this.renderVideo()}
        </div>
      );
    }
    if (!this.state.photoLoadingAllowed) {
      heroImages = [heroImages[0]];
    }

    // Photo rotator background
    return heroImages.map(({ alt, imageUrl }, i) => {
      if (i > this.state.photoIndex + 1 && i !== this.state.prevPhotoIndex) {
        return null;
      }
      return (
        <CustomImg
          key={i}
          index={i}
          alt={alt || this.props.heading || this.props.subheading}
          className={classNames(
            'pm-header-bg',
            'header-bg',
            'bg-cover',
            i === this.state.photoIndex ? 'header-bg-show' : 'header-bg-hide',
            i === this.state.prevPhotoIndex ? 'header-bg-prev' : null,
            this.props.headerTransitionType === 'ht_fade' ? 'transition-fade' : null,
            this.props.headerTransitionType === 'ht_slide' ? 'transition-slide' : null,
            (this.props.isFeatureActive('transition_timing') && this.props.headerTransitionTiming) ? this.props.classes.transitionTiming : null,
          )}
          defer
          onLoad={this.onPhotoLoad}
          priority="primary"
          size="lg"
          src={imageUrl}
          style={{
            ...style,
          }}
        />
      );
    });
  }

  renderHeading() {
    if (this.props.heading || this.props.logoUrl) {
      const style = {
        color: this.props.theme.pageHeaderFont.color,
        fontFamily: this.props.theme.pageHeaderFont.family && `"${this.props.theme.pageHeaderFont.family}"`,
        fontWeight: this.props.theme.headerFontWeight,
        textTransform: this.props.headingCase || this.props.theme.pageHeaderFont.case || 'uppercase',
      };
      return (
        <React.Fragment>
          {this.props.logoUrl && (
            <div
              className="pm-img-header"
              style={{
                margin: 'auto',
                maxWidth: '450px',
                position: 'relative',
                width: '90%',
              }}
            >
              <img
                style={{
                  aspectRatio: Number.isFinite(this.props.logoAspectRatio) ? `auto ${this.props.logoAspectRatio}` : 'auto',
                  width: '100%',
                }}
                src={this.props.logoUrl}
                alt={this.props.logoAlt || this.props.heading || this.props.subheading}
              />
            </div>
          )}
          {this.props.heading && (
            <AH variant="h1" className={`pm-page-heading pm-font-header pm-font-${this.props.theme.pageHeaderFontSize.split('_')[1]}`} style={style}>
              {this.props.heading}
            </AH>
          )}
        </React.Fragment>
      );
    }
    return null;
  }

  renderSubheading() {
    if (this.props.subheading) {
      const style = {
        color: this.props.theme.pageSubHeaderFont.color,
        fontFamily: this.props.theme.pageSubHeaderFont.family && `"${this.props.theme.pageSubHeaderFont.family}"`,
        fontWeight: this.props.theme.headerFontWeight,
        textTransform: this.props.theme.pageSubHeaderFont.case || 'uppercase',
      };
      return React.createElement(
        this.props.heading ? 'span' : 'h1',
        {
          className: `pm-page-subheading pm-font-subheader pm-font-${this.props.theme.pageSubHeaderFontSize.split('_')[1]}`,
          style,
        },
        this.props.subheading,
      );
    }
    return null;
  }

  renderLink() {
    if (!this.props.headerLinkText || !this.props.headerLinkUrl) {
      return null;
    }
    return (
      <div
        style={{
          position: 'relative',
          textAlign: 'center',
        }}
      >
        <CustomLink
          type="button"
          url={this.props.headerLinkUrl}
          isExternal={this.props.isExternal}
        >
          {this.props.headerLinkText}
        </CustomLink>
      </div>
    );
  }

  renderSpacer() {
    if (this.props.heading || this.props.logoUrl || this.props.subheading) {
      return null;
    }
    return (
      <div className="header-spacer" />
    );
  }

  sectionAction() {
    window.parent.postMessage({ immutableSection: { isCustomSection: true, sectionType: 'Header Image' }, page: this.props.customPageId }, '*');
  }

  previewControlClass() {
    if (this.props.activeSection?.sectionType === 'Header Image') {
      return 'preview-section-container-activable';
    }
    return 'preview-section-container-hoverable';
  }

  render() {
    const { isPreview } = this.props;
    const clickHandler = isPreview ? { onClick: this.sectionAction } : {}; // avoiding lint error
    if (visualRegressionsMode) {
      if (!visualRegressionsMode.startsWith('nav_header_footer') &&
          !visualRegressionsMode.startsWith('full_page')) {
        return null;
      }
    }

    const navbarOffset = isTransparentColor(this.props.theme.navbarBgColor) && this.props.maxNavbarHeight ? `${this.props.maxNavbarHeight * -1}px` : 0; //
    const headerStyle = {
      borderBottom: this.props.theme.headerBorderColor ? `4px solid ${this.props.theme.headerBorderColor}` : 'none',
      marginBottom: navbarOffset,
      position: 'relative',
      top: navbarOffset,
    };
    const iconTopMargin = `${(navbarOffset ? this.props.maxNavbarHeight : 0) + 16}px`;
    const style = {
      backgroundColor: this.props.backgroundColor,
      minHeight: this.props.headerBgFullHeight ? '100vh' : null,
    };
    // Get hero images
    const images = this.heroImages();
    return (
      <div className="container-fluid">
        <header
          {...clickHandler}
          className={isPreview ? `${this.previewControlClass()} no-click ${navbarOffset ? 'header-container__top-border' : ''}` : null}
          onMouseEnter={this.pauseCarousel}
          onMouseLeave={this.playCarousel}
          onMouseOver={this.pauseCarousel}
          onFocus={this.pauseCarousel}
          role="banner"
          style={headerStyle}
        >
          <div
            className={classNames({ 'page-header': true })}
            id="page-header"
            style={style}
          >
            {this.renderBackground()}
            {this.renderHeading()}
            {this.renderSpacer()}
            {this.renderSubheading()}
            {this.renderLink()}

            <button
              aria-label="Skip to page content"
              className="a header-chevron"
              onClick={Header.onSkipClick}
              onKeyUp={Header.onSkipClick}
              style={{ display: this.props.showSkipToPageContent ? 'block' : 'none' }}
              type="button"
            >
              <Icon icon={ChevronDown} />
            </button>

            {this.renderVideoPauseButton()}

            {images.length > 1 && (
              <React.Fragment>
                <button
                  aria-label="Previous Photo"
                  className="a header-control header-control-prev"
                  type="button"
                  onClick={this.handlePrevSlide}
                >
                  <Icon icon={ArrowLeft} />
                </button>
                <button
                  aria-label="Next Photo"
                  className="a header-control header-control-next"
                  type="button"
                  onClick={this.handleNextSlide}
                >
                  <Icon icon={ArrowRight} />
                </button>
              </React.Fragment>
            )}
            {
              isPreview && (
                <div style={{ right: '16px', top: iconTopMargin }} className="preview-section-container-controls">
                  <Edit onClick={this.sectionAction} />
                </div>
              )
            }
          </div>
        </header>
      </div>
    );
  }
}

Header.defaultProps = {
  backgroundColor: null,
  customHref: null,
  customPageId: null,
  headerBgFullHeight: false,
  headerLinkCustomPageId: null,
  headerLinkText: null,
  headerLinkUrl: null,
  headerTransitionType: 'ht_fade',
  heading: null,
  headingCase: null,
  heroImages: null,
  linkType: null,
  logoAlt: null,
  logoAspectRatio: null,
  logoUrl: null,
  photoUrl: null,
  showSkipToPageContent: false,
  subheading: null,
  videoCloudflareId: null,
  videoThumbnailUrl: null,
  videoUrl: null,
};

Header.propTypes = {
  backgroundColor: PropTypes.string,
  classes: PropTypes.object.isRequired,
  customHref: PropTypes.string,
  customPageId: PropTypes.number,
  headerBgFullHeight: PropTypes.bool,
  headerLinkCustomPageId: PropTypes.number,
  headerLinkText: PropTypes.string,
  headerLinkUrl: PropTypes.string,
  headerTransitionType: PropTypes.string,
  heading: PropTypes.string,
  headingCase: PropTypes.string,
  heroImages: PropTypes.arrayOf(PropTypes.shape({
    alt: PropTypes.string,
    imageUrl: PropTypes.string,
  })),
  isFeatureActive: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  linkType: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  logoAlt: PropTypes.string,
  logoAspectRatio: PropTypes.number,
  logoUrl: PropTypes.string,
  maxNavbarHeight: PropTypes.number.isRequired,
  photoUrl: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  restaurant: PropTypes.shape({
    customPages: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      slug: PropTypes.string,
    })),
    homePage: PropTypes.shape({
      heroImages: PropTypes.arrayOf(PropTypes.shape({
        alt: PropTypes.string,
        imageUrl: PropTypes.string,
      })),
    }),
  }).isRequired,
  showSkipToPageContent: PropTypes.bool,
  subheading: PropTypes.string,
  theme: themeShape.isRequired,
  videoCloudflareId: PropTypes.string,
  videoThumbnailUrl: PropTypes.string,
  videoUrl: PropTypes.string,
};

export default compose(
  withFeatureFlags,
  withRouter,
  withRestaurant,
  withTheme,
  withStyles(headerStyles),
  withWindowContext,
  withWindowSizeContext,
  connect(state => ({ activeSection: state.consumer.activeSection, isPreview: state.consumer.isPreview })),
  mapProps(({ window, windowSize, ...props }) => ({
    ...props,
    isMobile: windowSize.isMobile,
    maxNavbarHeight: window.maxNavbarHeight,
  })),
)(Header);
